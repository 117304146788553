
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}


/* REVIEWS */

#map-plug {display:none;}


.col-md-12, .service-package {
  background-color: #010606;
  color: whitesmoke;
  padding-top: 2em;
}


/* FOOTER */

.mini-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  position: sticky;
  font-size: 12px;
  background-color: #010606;
  padding-top: 4em;
  width: 100%;
}

.mini-footer a {
text-decoration: none;
color: #8f9296;
transition: all .2s ease;
}


.mini-footer span {
  color: #8f9296;
}

